import React,{useState} from "react"
import { Col, Container, Row,Modal } from "react-bootstrap"
import "./NewsCardList.scss"
import { Link } from "gatsby"
import { PageLinks } from "../../common/site/page-static-links"
import dateFormat from "dateformat";
import FormFields from "../../../static/forms/news_subscription.json"
import DefaultForm from "../forms/default-form-layout"
import ScrollAnimation from "react-animate-on-scroll"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const {
PreviewCTALink,
} = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")

const NewsCardList = ({ newsData, strapi_id,subscriptionData,newsValue }) => {
  const imagename = "blog.tile_image.small_image"

  
  const [show, setShow] = useState(false)

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""


  const formTitle = `Get news and insight straight to your inbox.`
  const cardData=subscriptionData?.node?.subscription_card
  const podcastData=subscriptionData?.node?.podcast_data
  return (
    <div className="news-card_wrapper-component">
      <Container>
        <div className="news-card-section">
          <Row>
            {newsData?.map((news, index) => {
              
              let processedImages
              processedImages =
                news?.node?.imagetransforms?.tile_image_Transforms ||
                JSON.stringify({})
              return (
                <>
                  <Col className="news-items col-12 col-md-6 col-xl-3" key={news.node?.slug}>
                  <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={index*100} offset={100}>

                    <div className="image-section">
                      <Link to={`/${PageLinks.news}/${news.node?.slug}/`}>
                      {/* <ImageModule
                        ImageSrc={news.node?.tile_image}
                        altText={`${news.node?.title}`}
                        imagetransforms={processedImages}
                        renderer="pic-src"
                        imagename={imagename}
                        strapi_id={news.node?.strapi_id}
                        classNames="img-fluid"
                      /> */}
                      <img src={news.node?.tile_image?.url} alt={`${news.node?.title} - haus & haus`} classNames="img-fluid"/>
                      </Link>
                    </div>
                    <div className="text-section">
                    <Link to={`/${PageLinks.news}/${news.node?.slug}/`}>
                      {news?.node?.title}
                      </Link>
                      <div className="time-date">
                        <p className="time">{news?.node?.read_time}</p>
                        {news?.node?.read_time&&
                        <span>/</span>
                        }
                        <p className="date">{dateFormat(news?.node?.date,"mmm dd, yyyy")}</p>
                      </div>
                    </div>
                    </ScrollAnimation>
                  </Col>
                  {index === 3 &&cardData&&newsValue?.value!=="podcasts"? (
                    <Col className="col-12">
                      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={index*100} offset={100}>
                      <div className="subscribe-card">
                        <div className="sub-content">
                        <h3>{cardData?.title}</h3>
                        <p className="description">
                        <ContentModule Content={cardData?.content?.data?.content} />
                        </p>
                        </div>
                     <a onClick={handleModal} href="javascript:void(0)" className="button button-filled-green">
                      <i className="icon"/>
                      {cardData?.cta?.title}
                      </a> 

                      </div>
                      </ScrollAnimation>
                    </Col>
                  )
                  :
                  index===3&&podcastData&&newsValue?.value==="podcasts"&&(
                    <Col className="col-12">
                      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={index*100} offset={100}>
                       
                      <div className="subscribe-card">
                        <div className="sub-content">
                        <h3>{podcastData.title}</h3>
                        <p className="description">
                        <ContentModule Content={podcastData?.content?.data?.content} />
                        </p>
                        </div>
                        <div className="logo-newss">
                          {podcastData?.image?.url&&
                          <img src={podcastData?.image?.url} alt="news logo"/>
                          }
                          {podcastData?.cta?.custom_link?
                            <a href={podcastData?.cta?.custom_link} target="_blank" className="button button-filled-green">
                            <i className="icon"/>
                            {podcastData?.cta?.title}
                            </a> 
                          :
                          <a onClick={handleModal} href="javascript:void(0)" className="button button-filled-green">
                          <i className="icon"/>
                          {podcastData?.cta?.title}
                          </a> 
                          }
                        </div>   
                    

                      </div>
                      </ScrollAnimation>
                    </Col>
                  )
                  }
                  
                </>
              )
            })}
          </Row>
        </div>
      </Container>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form news_subscribe"
      >
        <Modal.Body>
          <div className="popup-form-wrapper news_subscribe">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm 
              fields={FormFields}
              formTitle={formTitle}
              sourceUrl={pageurl}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default NewsCardList
